import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import ReactMarkdown from "react-markdown";

export default function DevelopmentPage({ data, location }) {
  let pages = data.allContentfulDevelopmentPages.nodes.sort(function (a, b) {
    return a.order - b.order;
  });

  return (
    <Layout location={location}>
      <div className="breadcrumbs d-flex align-items-center">
        <Link to="/development/" className="breadcrumb-link">
          Development
        </Link>
        <div className="divider">/</div>

        <div className="breadcrumb-item ms-space-300">
          {data.contentfulDevelopmentPages.name}
        </div>
      </div>

      <div className="markdown-content">
        <ReactMarkdown className="markdown-content">
          {data.contentfulDevelopmentPages.content.content}
        </ReactMarkdown>
      </div>
      <div class="next-back-links">
        {getLinks(pages, data.contentfulDevelopmentPages.name)}
      </div>
    </Layout>
  );
}

function getLinks(pages, name) {
  let backPage = null;
  let nextPage = null;

  for (let i = 0; i < pages.length; i++) {
    if (pages[i].name === name) {
      if (pages[i - 1] !== undefined) {
        backPage = pages[i - 1];
      }
      if (pages[i + 1] !== undefined) {
        nextPage = pages[i + 1];
      }
    }
  }

  return (
    <div className="next-back-links d-flex width-100 justify-content-between mb-space-800">
      {backPage !== null ? (
        <Link
          to={
            "/development/" +
            backPage.name.toLowerCase().replace(/\s/g, "-") +
            "/"
          }
        >
          <gds-button
            color="secondary"
            background="outlined"
            icon="arrow-left"
            icon-position="left"
          >
            Last: {backPage.name}
          </gds-button>
        </Link>
      ) : (
        <div></div>
      )}

      {nextPage !== null ? (
        <Link
          to={
            "/development/" +
            nextPage.name.toLowerCase().replace(/\s/g, "-") +
            "/"
          }
        >
          <gds-button
            color="secondary"
            background="outlined"
            icon="arrow-right"
            icon-position="right"
          >
            Next: {nextPage.name}
          </gds-button>
        </Link>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export const query = graphql`
  query DevPages($name: String) {
    contentfulDevelopmentPages(name: { eq: $name }) {
      name
      order
      content {
        content
      }
    }

    allContentfulDevelopmentPages {
      nodes {
        name
        order
        content {
          content
        }
      }
    }
  }
`;
